import './topbar.scss';
import React, { useEffect, useState } from 'react';
import {
	AppBar,
	createStyles,
	makeStyles,
	MenuItem,
	Theme,
	Toolbar,
	Typography,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			flexGrow: 1,
		},
	})
);

const TopBar: React.FC = () => {
	const classes = useStyles();

	const location = useLocation();

	const [title, setTitle] = useState<string>('Alex Borg');

	useEffect(() => {
		let title = 'Alex Borg';

		if (location && location.pathname !== '/' && location.pathname !== '') {
			let path = location.pathname.split('/')[1];
			title = `${path.charAt(0).toUpperCase()}${path.slice(1, path.length)} | Alex Borg`;
		}
		setTitle(title);
	}, [location]);

	return (
		<div className={classes.root}>
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<AppBar color="primary" position="fixed">
				<Toolbar component="nav" className="topbar-nav">
					<MenuItem component={Link} className="nav-item" to="/">
						<Typography variant="h6">About</Typography>
					</MenuItem>
					<MenuItem component={Link} className="nav-item" to="/folio">
						<Typography variant="h6">Folio</Typography>
					</MenuItem>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default TopBar;
