import { Card, Grid, Typography } from '@material-ui/core';
import React, { ReactNode } from 'react';
import './folioItem.scss';

interface OwnProps {
	title: string;
	paragraphs: Array<string>;
	imgPath: string;
	className?: string;
	widget?: ReactNode;
}

export type FolioItemProps = OwnProps;

const FolioItem: React.FC<FolioItemProps> = (props) => {
	return (
		<Card className={`folio-item-container ${props.className}`}>
			<Grid
				container
				direction="row"
				alignItems="flex-end"
			>
				<Grid item lg={6} className="folio-item-portion">
					<Typography variant="h2">{props.title}</Typography>
					<img className='folio-item-img' src={props.imgPath} alt="Grapple Rush screenshot" />
				</Grid>
				<Grid item lg={6} className="folio-item-portion">
					{props.paragraphs.map((paragraph, index) => (
						<Typography key={index} paragraph={true} variant="body1">
							{paragraph}
						</Typography>
					))}
				</Grid>
			</Grid>
			<div className="widget-container">{props.widget}</div>
		</Card>
	);
};

export default FolioItem;
