import './folio.scss';
import React from 'react';
import {
	Card,
	createStyles,
	Grid,
	Link,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import FolioItem from '../../components/folioItem/folioItem';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.default,
		},
		heading: {
			width: '100%',
		}
	})
);

const Folio: React.FC = () => {
	const classes = useStyles();

	return (
		<div className={`folio-container ${classes.root}`}>
			<Grid container direction="column" justify="center" alignItems="center">
				<Grid sm={8} md={8} lg={8} item>
					<Card className="folio-header">
						<Typography className={classes.heading} variant="h2">Portfolio</Typography>
					</Card>
				</Grid>
				<Grid sm={8} md={8} lg={8} item>
					<FolioItem
						className="folio-item-container"
						title="Grapple Rush"
						paragraphs={[
							"Grapple Rush is a third person Grapple Hook game created in Unity. The game's main attraction is its swinging mechanic, where players can swing around set points within the world. It also has other parkour-like mechanics such as wall running.",
							'My roles on the project included programming all behaviours (character controller, targeting system, menus e.t.c.) as the sole programmer on the team. I also designed the final level of the game and developed the games mechanics into what they are today.',
						]}
						imgPath="/img/grapple-rush.webp"
						widget={
							<iframe
								title="Grapple Rush Widget"
								src="https://itch.io/embed/87913?bg_color=212121&amp;fg_color=eeeeee&amp;link_color=fa5c5c&amp;border_color=363636"
								frameBorder="0"
							>
								<a href="https://grapplerush.itch.io/grapple-rush">
									Grapple Rush by grapplerush
								</a>
							</iframe>
						}
					/>
				</Grid>

				<Grid sm={8} md={8} lg={8} item>
					<FolioItem
						className="folio-item-container"
						title="Altr"
						paragraphs={[
							'Altr is a Mighty Switch Force Inspired 2D Platform Game. The game is centred around a switching mechanic, where the game environment changes states when you press e. The object of the game is to navigate the levels with the switching mechanic to find 5 gems. The gems activate a portal that leads to the next level.',
							'Altr was created in 4 weeks using Unity while I was still learning to program. My roles included scripting the games mechanics and menus and designing all the levels.',
						]}
						imgPath="/img/altr.webp"
						widget={
							<iframe
								title="Altr Widget"
								src="https://itch.io/embed/83387?bg_color=212121&amp;fg_color=eeeeee&amp;link_color=fa5c5c&amp;border_color=363636"
								frameBorder="0"
							>
								<a href="https://grapplerush.itch.io/grapple-rush">
									Grapple Rush by grapplerush
								</a>
							</iframe>
						}
					/>
				</Grid>

				<Grid sm={8} md={8} lg={8} item>
					<FolioItem
						className='folio-item-container'
						title='Simple Budget'
						paragraphs={[
							'Simple Budget is a free, easy to use tool, which makes it easy for anyone to create budgets and manage their finances. This empowers users to make better financial decisions with confidence.',
							'Simple Budget has a high degree of customisation, allowing users to create budgets from templates (or from scratch) and dynamically add categories and budget items to suit their needs. In addition, it provides a bar chart with a breakdown of all expenses, for quick and easy analysis',
						]}
						imgPath='/img/SimpleBudget.png'
						widget={
							<Typography paragraph={true} variant="body1">
								Simple Budget is a personal project, built completely by me. It is a React, Single Page Application, powered by the IBM Carbon Design System. It is still in active development, with new features being relased every week! Check out the app at <Link underline='always' color='inherit' href='https://budget.alexdborg.com'>budget.alexdborg.com</Link>
						</Typography>
						}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default Folio;
