import './App.scss';
import React from 'react';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/home/home';
import Folio from './pages/folio/folio';
import TopBar from './components/TopBar/topbar';
import {
	Card,
	createMuiTheme,
	createStyles,
	makeStyles,
	Theme,
	ThemeProvider,
	Typography,
} from '@material-ui/core';

const darkTheme = createMuiTheme({
	palette: {
		type: 'dark',
		primary: {
			main: '#333',
		},
		secondary: {
			main: '#f48fb1',
		},
		background: {
			default: '#212121',
			paper: '#424242',
		},
	},
});

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		app: {
			backgroundColor: '#212121',
		},
		compensateTopBar: {
			backgroundColor: '#212121',
		},
	})
);

function App() {
	const classes = useStyles();

	return (
		<div className={`App ${classes.app}`}>
			<ThemeProvider theme={darkTheme}>
				<Router>
					<TopBar />
					<div className={`compensate-topbar ${classes.compensateTopBar}`}>
						<Switch>
							<Route exact path="/">
								<Home />
							</Route>
							<Route path="/folio">
								<Folio />
							</Route>
						</Switch>
						<Card>
							<Typography variant="body1" className="copyright">
								Copyright © 2021 Alex Borg All Rights Reserved.
							</Typography>
						</Card>
					</div>
				</Router>
			</ThemeProvider>
		</div>
	);
}

export default App;
