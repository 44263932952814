import './home.scss';
import React from 'react';
import {
	Card,
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
			backgroundColor: theme.palette.background.default,
		},
	})
);

const Home: React.FC = () => {
	const classes = useStyles();

	return (
		<div className={`home-container ${classes.root}`}>
			<Grid container direction="column" justify="center" alignItems="center">
				<Grid sm={8} md={8} lg={8} item>
					<Card className="home-header">
						<Typography variant="h1">Alex Borg</Typography>
						<Typography variant="h4">
							Cloud Application Developer
							<br />
							Cloud Solutions Architect
						</Typography>
					</Card>
				</Grid>
				<Grid className="home-article-grid" sm={8} md={8} lg={8} item>
					<Card className="home-about">
						<Typography className="center-text" variant="h2">
							About Me
						</Typography>

						<Typography paragraph={true} variant="body1">
							Hi! I'm Alex Borg.
						</Typography>

						<Typography paragraph={true} variant="body1">
							I'm a Ballarat based Application Developer and former Game
							Designer. I started as a game designer making levels for 2D and 3D
							platform games and grew to love programming and all things
							technology. I'm a graduate of RMIT University with a Bachelor of
							Design (Games) and a Bachelor of Information Technology (Games,
							Graphics, Programming).
						</Typography>

						<Typography paragraph={true} variant="body1">
							Within my professional career and as an AWS certified developer, I
							design and build fast, reliable and cost effective software
							solutions, primarily in the cloud and at scale. Through my work at
							IBM, I have created cloud native applications for a variety of
							organisations, including case management and cognitive assistant
							solutions for federal government, a custom reporting tool for
							energy sector, and data analytics platforms for health sector
							clients and a high volume transaction processing solution, using
							AWS serverless technologies.
						</Typography>

						<Typography paragraph={true} variant="body1">
							I have strong programming knowledge and I am experienced with
							various web technologies/frameworks and with building applications
							for desktop and back end systems. My current work includes writing
							cloud native applications, micro-services and various front ends
							for those applications. As I work over the full stack, I am
							experienced with various databases (relational and NoSQL) as well,
							including various flavours of Redis, Db2 and Microsoft SQL server.
						</Typography>

						<Typography paragraph={true} variant="body1">
							When I'm not building or tinkering with something, you can often
							find me walking/jogging in my local area and pretending to be good
							at video games.
						</Typography>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
};

export default Home;
